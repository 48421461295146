import React from 'react';
import { Link } from 'react-scroll';
import '../styles/Global.css';
import '../styles/Responsiveness.css';

import StreamlineFranchise from '../assets/Images/streamline-franchise.png'
import FranchiSyncLogo from '../assets/Images/franchisync-logo.png'

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-content">
        <img
          loading="lazy"
          srcSet={FranchiSyncLogo}
          className="franchise-management-system-logo"
        />
        <div className="franchise-management-system-title">Integrated POS and Franchise Management System</div>
        <div className="franchise-management-system-description">Streamline Franchise Operations</div>
        <img
          loading="lazy"
          srcSet={StreamlineFranchise}
          className="franchise-management-system-image"
        />
      </div>
    </div>
  );
};

export default Home;

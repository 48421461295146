// Navbar.jsx
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, animateScroll as scroll } from 'react-scroll';
import '../styles/Global.css';

import FranchiSyncLogo from '../assets/Images/franchisync-logo.png'

const NavigationBar = () => {
  return (
    <div>
      <Navbar className="navbar fixed-top" expand="lg">
        <Navbar.Brand onClick={() => scroll.scrollToTop()}>
          <img
            src={FranchiSyncLogo}
            alt="Logo"
            className="navbar-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto justify-content-end" style={{ width: '100%' }}>
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Home
            </Link>
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              About
            </Link>
            <Link
              to="features"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Features
            </Link>
            {/* <Link
              to="pricing"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Pricing
            </Link> */}
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Contact Us
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
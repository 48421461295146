import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Card from 'react-bootstrap/Card';
import ReactCardFlip from 'react-card-flip';
import '../styles/Global.css';
import '../styles/Responsiveness.css';

import CardImage1 from '../assets/Images/franchisee-branch-management.png';
import CardImage2 from '../assets/Images/inventory-management.png';
import CardImage3 from '../assets/Images/supply-chain-management.png';
import CardImage4 from '../assets/Images/monthly-sales-royalty-fee-management.png';
import CardImage5 from '../assets/Images/franchisee-payment-management.png';
import CardImage6 from '../assets/Images/comprehensive-dashboard.png';
import CardImage7 from '../assets/Images/report-generator.png';
import FlipIcon from '../assets/Vectors/flip-icon.png';


const cardDetails = [
  {
    title: 'Franchisee Branch Management',
    image: CardImage1,
    description:
      'Offers a user-friendly solution, simplifying the onboarding and efficient administration of franchisees and their corresponding branches through a centralized system, enhancing operational control and visibility.',
  },
  {
    title: 'Inventory Management',
    image: CardImage2,
    description:
      'Provides a centralized platform for real-time tracking, efficient stock management, and streamlined operations, ensuring businesses maintain optimal stock levels and enhance overall efficiency.',
  },
  {
    title: 'Supply Chain Management',
    image: CardImage3,
    description:
      'Offers a comprehensive solution for end-to-end visibility, real-time tracking, and seamless coordination of the entire supply chain, empowering businesses to enhance responsiveness and optimize their distribution networks.',
  },
  {
    title: 'Monthly Sales and Royalty Fee Management',
    image: CardImage4,
    description:
      'Facilitate automated monthly billing for franchisees, encompassing royalty fees and marketing fees, with the added flexibility of customization fee structures aligned with individual franchise agreements and contracts.',
  },
  {
    title: 'Franchisee Payment Management',
    image: CardImage5,
    description:
      'Empower efficient franchisee payment management, tracking of outstanding payments, and monitoring of aging with integrated tools, complemented by automated reminders and notifications for timely payments, effectively reducing payment delays.',
  },
  {
    title: 'Comprehensive Dashboard',
    image: CardImage6,
    description:
      'Streamline decision-making with customizable dashboards, offering a centralized view to track performance metrics, sales data, and operational information for quick and informed insights.',
  },
  {
    title: 'Report Generator',
    image: CardImage7,
    description:
      'Enhance operational clarity with a reporting tool that delivers in-depth analytics, shedding light on franchise performance, sales trends, and financial metrics, ensuring strategic decision-making is well-informed and precise.',
  },
];

const Features = () => {
  const [isFlipped, setFlipped] = useState(Array(cardDetails.length).fill(false));

  const handleMouseEnter = index => {
    const newFlipped = [...isFlipped];
    newFlipped[index] = true;
    setFlipped(newFlipped);
  };

  const handleMouseLeave = index => {
    const newFlipped = [...isFlipped];
    newFlipped[index] = false;
    setFlipped(newFlipped);
  };

  return (
    <div className="features-container">
      <div className="features-title">Features</div>

      <div className="card-grid">
        {cardDetails.map((card, index) => (
          <div className="card-container" key={index}>
            <div className="feature-name">{card.title}</div>
            <ReactCardFlip isFlipped={isFlipped[index]} flipDirection="horizontal">
              <Card
                className="card-with-skyblue"
                style={{ width: 430, height: 300 }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <div className="icon">
                  <img
                    src={FlipIcon}
                    alt="Left Chevron Icon"
                    className={`flip-icon ${isFlipped[index] ? 'flipped' : ''}`}
                  />
                </div>
                <Card.Img variant="top" src={card.image} alt={`Card image ${index + 1}`} className="card-image" />
              </Card>
              <Card
                className="card-with-skyblue"
                style={{ width: 430, height: 300 }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <div className="icon">
                  <img
                    src={FlipIcon}
                    alt="Left Chevron Icon"
                    className={`flip-icon ${isFlipped[index] ? 'flipped' : ''}`}
                  />
                </div>
                <Card.Body>
                  <Card.Text className="card-description">{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </ReactCardFlip>
          </div>
        ))}
      </div>

      <div className="features-description">With FranchiSync, franchising your business is now made easier.</div>
    </div>
  );
};

export default Features;

// About.jsx
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/Global.css';
import '../styles/Responsiveness.css';

import MytLogo from '../assets/Images/myt-logo.png';
import FranchiSyncLogo from '../assets/Images/franchisync-logo.png';

const About = () => {
  return (
    <Container id="about" className="about-container" style={{ marginTop: '100px' }}>
      <Row className="about-header">
        <Col>
          <div className="about-title">About FranchiSync</div>
          <div className="about-description">
            <span style={{ color: 'rgba(61,169,252,1)' }}>FranchiSync</span> is a
            cutting-edge software developed by MYT SoftDev Solutions, <br />
            dedicated to streamlining and optimizing the entire franchising process.<br />
          </div>
        </Col>
      </Row>
      <Row className="about-logos" >
        <Col className="text-right">
          <img
            loading="lazy"
            src={MytLogo}
            alt="MYT Logo"
            className="myt-logo"
          />
        </Col>
        <Col className="text-left" >
          <img
            loading="lazy"
            src={FranchiSyncLogo}
            alt="FranchiSync Logo"
            className="franchisync-logo"
          />
        </Col>
      </Row>
      <Row className="about-details text-center">
        <Col>
          At its core,{" "}
          <span style={{ color: 'rgba(61,169,252,1)' }}>FranchiSync</span> is
          designed to address common challenges faced by franchises. <br />
          It acts as a comprehensive solution, tackling issues such as the difficult management <br />
          of franchises, manual tracking of sales data, inefficient invoice tracking, and the absence <br />
          of centralized visibility into inventory levels. <br />
        </Col>
      </Row>
    </Container>
  );
};

export default About;

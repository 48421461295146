import React from 'react';
import '../styles/Global.css';
import '../styles/Responsiveness.css';

import FranchiSyncLogo from '../assets/Images/franchisync-logo.png';
import Facebook from '../assets/Vectors/fb-icon.png';
import LinkedIn from '../assets/Vectors/linkedin-icon.png';
import Email from '../assets/Vectors/email-icon.png';

const Footer = () => {
  return (
    <footer className="bg-custom-color text-dark p-3 text-center div-27">
      <div className="footer align-items-start">
        <div>
          <img
            src={FranchiSyncLogo}
            alt="Logo"
            className="footer-logo mb-2"
          />
          <p className="powered-by-text">Powered by MYT Softdev Solutions, Inc. @ 2024</p>
        </div>
        <div className='icon-section'>
          <a
            href="https://www.facebook.com/MYTSoftDevSolutions"
            target="_blank" rel="noopener noreferrer"
          >
            <img
              loading="lazy"
              src={Facebook}
              alt="Facebook"
              className="social-icon"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/myt-software/about/"
            target="_blank" rel="noopener noreferrer"
            className="social-icon"
          >
            <img
              loading="lazy"
              src={LinkedIn}
              alt="LinkedIn"
              className="social-icon"
            />
          </a>
          <a
            href="mailto:info@mytsoftdevsolutions.com"
            className="social-icon"
          >
            <img
              loading="lazy"
              src={Email}
              alt="Email"
              className="social-icon"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import '../styles/Global.css';
import '../styles/Responsiveness.css';

import ContactImage from '../assets/Images/contact-us.png';
import FacebookIcon from '../assets/Vectors/fb-icon.png';
import EmailIcon from '../assets/Vectors/email-icon.png';
import WebIcon from '../assets/Vectors/web-icon.png';

const Contact = () => {
  const handleFacebookClick = () => {
    // Redirect to the Facebook page when the icon is clicked
    window.location.href = 'https://www.facebook.com/mytsoftdevsolutions';
  };

  return (
    <div className='contact-page'>
      <div className="contact-title">Contact Us</div>
      <div className="contact-container">
        <div className="contact-image">
          <img
            loading="lazy"
            src={ContactImage}
            alt="Contact Us"
            className="contact-image-element"
          />
        </div>
        <div className="contact-details">
          <div className="contact-item">
            <a href="https://www.facebook.com/mytsoftdevsolutions" target="_blank" rel="noopener noreferrer">
              <img
                loading="lazy"
                src={FacebookIcon}
                alt="Facebook Icon"
                className="contact-icon"
              />
            </a>
            <div className="contact-info">MYT SoftDev Solutions</div>
          </div>
          <div className="contact-item">
          <a href="mailto:info@mytsoftdevsolutions.com">
            <img
              loading="lazy"
              src={EmailIcon}
              alt="Email Icon"
              className="contact-icon"
            />
            </a>
            <div className="contact-info">info@mytsoftdevsolutions.com</div>
          </div>
          <div className="contact-item">
          <a href="https://mytsoftdevsolutions.com/" target="_blank" rel="noopener noreferrer">
            <img
              loading="lazy"
              src={WebIcon}
              alt="Website Icon"
              className="contact-icon"
            />
            </a>
            <div className="contact-info">https://mytsoftdevsolutions.com/</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

// App.jsx
import React, { useState, useEffect } from 'react';
import NavigationBar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import { Element } from 'react-scroll';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const App = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <NavigationBar />
      <div>
        <Element name="home">
          <Home />
        </Element>
        <Element name="about">
          <About />
        </Element>
        <Element name="features">
          <Features />
        </Element>
        {/* <Element name="pricing">
          <Pricing />
        </Element> */}
        <Element name="contact">
          <Contact />
        </Element>
      </div>
      <Footer />
      <MessengerCustomerChat 
        pageId="218781067989334" 
        appId="730400515509553"/>
    </div>
  );
};

export default App;
